import React, { useContext } from "react";
import styled from "styled-components";
import { OrganizersContext } from "../../../contexts/Organizers";

import { Card, CardHeader, CardContent, Typography, Chip, Button } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import EuroIcon from '@material-ui/icons/Euro';
import { AuthContext } from "../../../contexts/Auth";
import { isCanManageOrganizers } from "../../../domain/resolvePermission";
import { useHistory } from "react-router-dom"

export const OrganizersList = () => {
    const organizersContext = useContext(OrganizersContext);
    const authContext = useContext(AuthContext);

    const organizers = organizersContext.organizers || [];
    const canManageOrganizers = isCanManageOrganizers(authContext.currentUser);

    return (
        <Container>
            {organizers
                ?.map(organizer => <Organizer
                    key={organizer.id}
                    organizer={organizer}
                    canManage={canManageOrganizers}
                />)}
        </Container>
    )
};

const Organizer = ({ organizer, canManage }) => {
    const history = useHistory();

    return (
        <StyledCard>
            <CardHeader
                title={<div>
                    <CardHeaderTitle>
                        { organizer.name }                        
                    </CardHeaderTitle>
                </div>}
            >
            </CardHeader>
            <CardContent>
                <Typography variant="body1" component="p">
                    {
                        Boolean(canManage) &&
                        <StyledButton variant="outlined" color="secondary" onClick={() => history.push(`/events/${organizer.id}`)} >
                            Редактировать
                        </StyledButton>
                    }
                </Typography>
            </CardContent>
        </StyledCard>
    );
};


const Container = styled.div`
`;

const CardHeaderTitle = styled.div`
display: flex;
align-items: center;
margin-bottom: 0.75rem;
`

const StyledCard = styled(Card)`
margin-bottom: 1rem;
`;


const StyledButton = styled(Button)`
margin-right:.5rem!important;
`;

