import React, { useContext, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
    makeStyles, Box, TextField, Button, Typography,
    FormControlLabel, Checkbox, IconButton,
    FormControl, InputLabel, Select, MenuItem,
} from "@material-ui/core";
import {DeleteForever} from "@material-ui/icons";
import cs from 'classnames';
import {DateTimePicker} from "@material-ui/pickers"
import { ClubsContext } from "../../../contexts/Clubs";
import { OrganizersContext } from "../../../contexts/Organizers";
import { AuthContext } from "../../../contexts/Auth";
import { isCanManageClubs } from "../../../domain/resolvePermission";
import { DeleteClubDialog } from "./DeleteClubDialog";
import { ImageLoaderType, ImageLoader } from "../shared/ImageLoader";
import { SelectOrganizer } from "../shared/SelectOrganizer";
import {useHistory} from "react-router-dom"
import moment from "moment";
import { isBoolean as _isBoolean, isArray as _isArray, debounce as _debounce } from "lodash";
import { uid } from 'rand-token';

const DEFAULT_MEMBERSHIP_FEE_RUB = 2500;
const DEFAULT_MEMBERSHIP_FEE_EUR = 15;
const ALIAS_REGEX = /^[a-z0-9-_]+$/;
const ALIAS_STATUS = {
    NONE: '',
    IS_TAKEN: 'Псевдноим занят',
    IS_INVALIDATE: 'В псевдониме могут быть использованы только: a-z, 0-9, -, _'
}

export const CreateClub = ({ club }) => {
    const classes = useStyles();
    
    const clubsContext = useContext(ClubsContext);
    const organizersContext = useContext(OrganizersContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const clubId = club?.id;

    const [isEvent, setIsEvent] = useState(club?.isEvent || false);
    const [organizerId, setOrganizerId] = useState(club?.organizer?.id || '')
    const [name, setName] = useState(club?.name || "");

    const [alias, setAlias] = useState(club?.alias || '');
    const [isChecking, setIsChecking] = useState(false);
    const [aliasStatus, setAliasStatus] = useState('');
    
    const [logoImg, setLogoImg] = useState(club?.logoUrl || null);
    const [themeImg, setThemeImg] = useState(club?.themeUrl || null);
    const [managerId, setManagerId] = useState(club?.managerId || "");
    const [communicationInfo, setCommunicationInfo] = useState(club?.communicationInfo || "");
    const [description, setDescription] = useState(club?.description || "");
    const [clubPayment, setClubPayment] = useState(club?.clubPayment ? moment(club.clubPayment).utcOffset(6, true).toDate() : null);
    const [isPrivate, setIsPrivate] = useState(club?.isPrivate || false);
    const [hasMembershipFee, setMembershipFee] = useState(club?.hasMembershipFee || false);
    const [membershipFeeRub, setMembershipFeeRub] = useState(club?.membershipFeeRub || DEFAULT_MEMBERSHIP_FEE_RUB);
    const [membershipFeeEur, setMembershipFeeEur] = useState(club?.membershipFeeEur || DEFAULT_MEMBERSHIP_FEE_EUR);
    const [defaultTrainerId, setDefaultTrainerId] = useState(club?.defaultTrainerId || "");
    const [withChallengesBanners, setWithChallengesBanners] = useState(_isBoolean(club?.settings?.withChallengesBanners) ? club.settings.withChallengesBanners  : true);
    const [withVisibleClubMembers, setWithVisibleClubMembers] = useState(_isBoolean(club?.settings?.withVisibleClubMembers) ? club.settings.withVisibleClubMembers  : false);
    const [withVisibleRating, setWithVisibleRating] = useState(_isBoolean(club?.settings?.withVisibleRating) ? club.settings.withVisibleRating  : true);
    const [controlPanelVisibility, setControlPanelVisibility] = useState(club?.settings?.controlPanelVisibility || 0);
    const [disabled, setDisabled] = useState(club?.disabled || false);

    const [links, setLinks] = useState(club?.settings?.links ? JSON.parse(club.settings.links) : []);

    const [isDialog, setDialog] = useState(false);

    useEffect(() => {
        const canManageClubs = isCanManageClubs(authContext.currentUser);

        if (!canManageClubs)
            history.push("/clubs")
    }, [])

    const handleCreateClub = async (e) => {
        e.preventDefault();
        const clubData = {
            alias,
            name,
            managerId: managerId ? managerId.trim() : '',
            communicationInfo,
            description,
            isPrivate: isEvent ? 0 : Number(isPrivate),
            hasMembershipFee: isEvent ? 0 : Number(hasMembershipFee),
            disabled: Number(disabled),
            isEvent: Number(isEvent),
            organizerId: isEvent ? organizerId : '',
            defaultTrainerId: defaultTrainerId,
        };

        if (hasMembershipFee) {
            clubData.membershipFeeRub = membershipFeeRub;
            clubData.membershipFeeEur = membershipFeeEur;
        } else {
            clubData.clubPayment = clubPayment ? moment(clubPayment).utcOffset(0, true) : '';
        }
        
        const clubSettingsData = {
            withChallengesBanners: Number(withChallengesBanners),
            withVisibleClubMembers: Number(withVisibleClubMembers),
            withVisibleRating: Number(withVisibleRating),
            links: JSON.stringify(links),
            controlPanelVisibility: controlPanelVisibility,
        }

        clubId ?
            await clubsContext.updateClub(clubId, clubData, clubSettingsData, logoImg, themeImg)
            : await clubsContext.createClub(clubData, clubSettingsData, logoImg, themeImg);
        
        window.location = '/clubs';
    }

    const addNewLink = () => {
        let newId;

        let isUniqueId = false;
        while (!isUniqueId) {
            newId = uid(3);
            isUniqueId = !links.some(link => link.id === newId);
        }

        const newLink = { id: newId, text: '', href: '' };
        setLinks([...links, newLink]);
    };
    
    const updateLink = (id, field, value) => {
        const updatedLinks = links.map(link =>
            link.id === id ? { ...link, [field]: value } : link
        );
        setLinks(updatedLinks);
    };

    const deleteLink = id => {
        const updatedLinks = links.filter(link => link.id !== id);
        setLinks(updatedLinks);
    };

    const checkAlias = async (alias) => {
        setIsChecking(true);

        if (alias === '' || (club?.alias && club.alias === alias)) {
            setAliasStatus(ALIAS_STATUS['NONE']);
        } else if (!ALIAS_REGEX.test(alias)) {
            setAliasStatus(ALIAS_STATUS['IS_INVALIDATE']);
        } else {
            const isTaken = await clubsContext.requestAliasCheck(alias);
            if (isTaken) {
                setAliasStatus(ALIAS_STATUS['IS_TAKEN']);
            } else if (aliasStatus != ALIAS_STATUS['IS_INVALIDATE']) {
                setAliasStatus(ALIAS_STATUS['NONE']);
            }
        }
        setIsChecking(false);
    }

    const debouncedCheckAlias = useCallback(_debounce(checkAlias, 500), []);

    const handleAliasChange = (e) => {
        const newAlias = e.target.value;
    
        debouncedCheckAlias(newAlias);
    
        setAlias(newAlias);
    };

    return (
        <Container component={"form"} onSubmit={handleCreateClub}>
            {clubId ?
                <>
                    <DeleteClubDialog
                        isDialog={isDialog}
                        setDialog={setDialog}
                        clubId={clubId}
                    />
                    <Typography variant="h5">Обновить клуб</Typography>
                </>
                : <>
                    <Typography variant="h5">Создать клуб</Typography>
                </>
            }
                <ImageLoaderContainer>
                    <ImageLoader type={ImageLoaderType.CUSTOM} onChange={setLogoImg} value={logoImg} />
                </ImageLoaderContainer>
                <ImageLoaderContainer>
                    <ImageLoader type={ImageLoaderType.THEME} onChange={setThemeImg} value={themeImg} />
                </ImageLoaderContainer>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isEvent}
                            onChange={(e) => setIsEvent(!isEvent)}
                            color="secondary"
                            disabled={!!club}
                        />
                    }
                    className={classes.checkboxContainer}
                    label="EVENT-клуб"
                />
                {isEvent && organizersContext?.organizers && <SelectOrganizer
                    value={organizerId}
                    onChange={setOrganizerId}
                    organizers={organizersContext?.organizers}
                    className={classes.input}
                />}
                <TextField
                    label={"Название"}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={classes.input}
                    variant="outlined"
                />
                <TextField
                    label={"Псевдоним"}
                    value={alias}
                    onChange={handleAliasChange}
                    className={classes.input}
                    variant="outlined"
                    error={!!aliasStatus}
                    helperText={aliasStatus}
                />
                <TextField
                    label={"ID Руководителя"}
                    value={managerId}
                    onChange={(e) => setManagerId(e.target.value)}
                    type="number"
                    onWheel={e => {
                        if (e.target instanceof HTMLElement) {
                            e.target.blur()
                        }
                    }}
                    className={cs(classes.input, classes.numberInput)}
                    variant="outlined"
                />
                <TextField
                    label={"Контакты для связи"}
                    value={communicationInfo}
                    onChange={(e) => setCommunicationInfo(e.target.value)}
                    inputProps={{ maxLength: 250 }}
                    className={classes.input}
                    variant="outlined"
                />
                <TextField
                    label={"Описание"}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className={classes.input}
                    variant="outlined"
                    multiline
                    rows={3}
                />
                {_isArray(links) ? <>{links.map((link) =>
                    <LinksContainer key={link.id}>
                        <TextField
                            label={`Текст кнопки ${link.id}`}
                            required
                            value={link.text || ""}
                            onChange={e => updateLink(link.id, 'text', e.target.value)}
                            className={cs(classes.input, classes.linkInput)}
                            variant="outlined"
                        />
                        <TextField
                            label={`Ссылка кнопки ${link.id}`}
                            required
                            value={link.href || ""}
                            onChange={e => updateLink(link.id, 'href', e.target.value)}
                            className={cs(classes.input, classes.linkInput)}
                            variant="outlined"
                        />
                        <IconButton color="secondary" variant="contained"
                            onClick={() => deleteLink(link.id)}><DeleteForever/></IconButton>
                    </LinksContainer> 
                )
                }</>
                : null}
                <Button color="secondary" className={classes.input} variant="outlined" onClick={addNewLink}>
                    {'Добавить кнопку'}
                </Button>
                {!isEvent && <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hasMembershipFee}
                                onChange={(e) => setMembershipFee(!hasMembershipFee)}
                                color="secondary"
                            />
                        }
                        className={classes.checkboxContainer}
                        label="Членский взнос"
                    />
                    {hasMembershipFee ? <>
                        <TextField
                            label={"Членский взнос в рублях"}
                            required
                            type="number"
                            onChange={(e) => setMembershipFeeRub(e.target.value)}
                            onWheel={e => {
                                if (e.target instanceof HTMLElement) {
                                    e.target.blur()
                                }
                            }}
                            className={cs(classes.input, classes.numberInput)}
                            variant="outlined"
                            value={membershipFeeRub}
                        />
                        <TextField
                            label={"Членский взнос в евро"}
                            required
                            type="number"
                            onChange={(e) => setMembershipFeeEur(e.target.value)}
                            onWheel={e => {
                                if (e.target instanceof HTMLElement) {
                                    e.target.blur()
                                }
                            }}
                            className={cs(classes.input, classes.numberInput)}
                            variant="outlined"
                            value={membershipFeeEur}
                        />
                    </>
                    : <DateTimePicker 
                        value={clubPayment} 
                        onChange={setClubPayment} 
                        inputVariant="outlined" 
                        ampm={false}
                        className={classes.input}
                        label={"Оплачен до"}
                        clearable
                    />}
                    <TextField
                        label={"ID тренера по умолчанию"}
                        value={defaultTrainerId}
                        onChange={(e) => setDefaultTrainerId(e.target.value)}
                        type="number"
                        onWheel={e => {
                            if (e.target instanceof HTMLElement) {
                                e.target.blur()
                            }
                        }}
                        className={cs(classes.input, classes.numberInput)}
                        variant="outlined"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isPrivate}
                                onChange={(e) => setIsPrivate(!isPrivate)}
                                color="secondary"
                            />
                        }
                        className={classes.checkboxContainer}
                        label="Закрытый клуб"
                    />
                </>}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={withChallengesBanners}
                            onChange={(e) => setWithChallengesBanners(!withChallengesBanners)}
                            color="secondary"
                        />
                    }
                    className={classes.checkboxContainer}
                    label="Видны баннеры"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={withVisibleClubMembers}
                            onChange={(e) => setWithVisibleClubMembers(!withVisibleClubMembers)}
                            color="secondary"
                        />
                    }
                    className={classes.checkboxContainer}
                    label="Видны члены клуба"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={withVisibleRating}
                            onChange={(e) => setWithVisibleRating(!withVisibleRating)}
                            color="secondary"
                        />
                    }
                    className={classes.checkboxContainer}
                    label="Виден рейтинг"
                />
                <FormControl variant="outlined" className={classes.input}>
                    <InputLabel id="visibility-select-label" >Иконки</InputLabel>
                    <Select
                        required
                        value={controlPanelVisibility}
                        labelId="visibility-select-label"
                        label="Иконки"
                        onChange={(e) => setControlPanelVisibility(e.target.value)}
                    >
                        <MenuItem value={0}>Показать все</MenuItem>
                        <MenuItem value={1}>Скрыть все</MenuItem>
                        <MenuItem value={2}>Скрыть лишнее (оплата, тренер)</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={disabled}
                            onChange={(e) => setDisabled(!disabled)}
                            color="secondary"
                        />
                    }
                    className={classes.checkboxContainer}
                    label="Деятельность приостановлена"
                />
                <Button 
                    type="submit" 
                    color="secondary" 
                    className={classes.input} 
                    variant="outlined"
                    disabled={isChecking || !!aliasStatus}
                >
                    {club?.id ? 'Обновить' : 'Создать'}
                </Button>
            {club?.id &&
                <Button color="secondary" className={classes.input} variant="contained" onClick={() => setDialog(!isDialog)}>В архив</Button>}

        </Container>
    )
};

const Container = styled(Box)`
    max-width: 1024px;
    margin: 1rem auto;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
`;


const ImageLoaderContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    align-items: center;
`;

const LinksContainer = styled(Box)`
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const useStyles = makeStyles({
    input: {
        width: "400px",
        marginTop: "1rem",
    },
    linkInput: {
        marginRight: '1rem',
    },
    numberInput: {
        /* Chrome, Safari, Edge, Opera */
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        /* Firefox */
        "& input[type=number]": {
            "-moz-appearance": "textfield"
        }
    },
    checkboxContainer: {
        marginTop: "1rem",
    }
});