import React from "react";
import { ClubsProvider } from "../../../contexts/Clubs";
import { OrganizersProvider } from "../../../contexts/Organizers";
import { CreateClub } from "../../components/clubs/CreateClub";

import Header, { pages } from "../Header";

export const CreateClubPage = () => {
    return (
        <ClubsProvider>
            <OrganizersProvider>
                <Header activePage={pages.CLUBS} />
                <CreateClub />
            </OrganizersProvider>
        </ClubsProvider>
    );
};