import React from "react";
import { OrganizersProvider } from "../../../contexts/Organizers";
import { OrganizersContainer } from "../../components/organizers/OrganizersContainer";

import Header, { pages } from "../Header";

export const OrganizersPage = () => {
    return (
        <OrganizersProvider>
            <Header activePage={pages.ORGANIZERS} />
            <OrganizersContainer />
        </OrganizersProvider>
    );
};