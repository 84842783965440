import React from "react";
import { OrganizersProvider } from "../../../contexts/Organizers";
import { CreateOrganizer } from "../../components/organizers/CreateOrganizer";

import Header, { pages } from "../Header";

export const CreateOrganizerPage = () => {
    return (
        <OrganizersProvider>
            <Header activePage={pages.CLUBS} />
            <CreateOrganizer />
        </OrganizersProvider>
    );
};