import React, { useEffect, useState } from "react";

import { restInstance } from "../domain/http/rest";

export const OrganizersContext = React.createContext(undefined);

const createFormData = (organizerData, files) => {
    const formData = new FormData();

    Object.keys(organizerData).forEach(key => {
        formData.append(`${key}`, organizerData[key]);
    });

    Object.keys(files).forEach(bannerId => {
        formData.append(bannerId, files[bannerId]);
    });

    return formData
}

export const OrganizersProvider = ({ children }) => {
    const [organizers, setOrganizers] = useState([]);
    const [organizer, setOrganizer] = useState(null);

    const requestOrganizers = async () => {
        try {
            const { data } = await restInstance.get("/organizers");
            setOrganizers(data);
        } catch (e) {
            console.log("loadOrganizers", e);
        }
    };

    useEffect(() => {
        requestOrganizers();
    }, []);

    const requestOrganizer = async (organizerId) => {
        try {
            const { data } = await restInstance.get(`/organizers/${organizerId}`);
            setOrganizer(data);
        } catch (e) {
            console.log("requestOrganizer", e);
        }
    };


    const createOrganizer = async (organizerData, files) => {
        try {
            const formData = createFormData(organizerData, files)
            const response = await restInstance.post(`/organizers`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response.data;
        } catch (e) {
            console.log("createOrganizer", e);
        }
    };

    const updateOrganizer = async (organizerId, organizerData, files) => {
        try {
            const formData = createFormData(organizerData, files)
            await restInstance.put(`/organizers/${organizerId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (e) {
            console.log("updateOrganizer", e);
        }
    };

    return (
        <OrganizersContext.Provider
            value={{
                organizers,
                organizer,
                requestOrganizer,
                createOrganizer,
                updateOrganizer,
            }}>
            {children}
        </OrganizersContext.Provider>
    );
};
