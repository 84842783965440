import React from "react";
import { ChallengesProvider } from "../../contexts/Challenges";
import { OrganizersProvider } from "../../contexts/Organizers";
import { LaunchChallenge } from "../components/challenges/LaunchChallenge";

import Header, { pages } from "./Header";

export const LaunchChallengePage = () => {
    return (
        <ChallengesProvider>
            <OrganizersProvider>
                <Header activePage={pages.CHALLENGES} />
                <LaunchChallenge />
            </OrganizersProvider>
        </ChallengesProvider>
    );
};