import React from "react";
import {
  makeStyles, Select, MenuItem,
  FormControl, InputLabel,
} from "@material-ui/core";


export const SelectOrganizer = ({ organizers, onChange, value, className }) => {
    const classes = useStyles();

    return <FormControl variant="outlined" className={className}>
    <InputLabel id="visibility-select-label" >Event</InputLabel>
    <Select
        value={value}
        labelId="visibility-select-label"
        label="Event"
        onChange={(e) => onChange(e.target.value)}
    >
      <MenuItem value={''}>Не выбран</MenuItem>
      {organizers?.map(o => <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>)}
    </Select>
</FormControl>;
};

const useStyles = makeStyles({
});