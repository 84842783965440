import React from 'react';
import { makeStyles, FormControlLabel, Checkbox, Button, IconButton, TextField } from '@material-ui/core';
import { isArray as _isArray } from "lodash";
import {DeleteForever} from "@material-ui/icons";
import { uid } from 'rand-token';

const MAX_NUMBER_OF_BANNERS = 4;

const getBannerTemplate = (id) => {
  return { 
    id,
    on: null,
    name: '',
    date: '',
    url: '',
    description: '',
    distances: '',
    txt: [
      getBannerButtonTemplate(1),
      getBannerButtonTemplate(2),
      getBannerButtonTemplate(3),
    ],
    img: null,
    img_mini: null,
  };
}

const getBannerButtonTemplate = (id) => {
  return {
    id,
    on: null,
    name: "",
    url: "",
  }
}

export const OrganizersBanners = ({
  banners,
  setBanners,
  files,
  setFiles,
}) => {
    const classes = useStyles();

    const addNewBanner = () => {
      let newId;

      let isUniqueId = false;
      while (!isUniqueId) {
          newId = uid(3);
          isUniqueId = !banners.some(banner => banner.id === newId);
      }

      const newBanner = getBannerTemplate(newId);
            
      setBanners([...banners, newBanner]);
    };

    const deleteBanner = (id, name) => {
      if (!confirm(`Удалить баннер "${name}"?`)) return;
      const updatedBanners = banners.filter(banner => banner.id !== id);
      setBanners(updatedBanners);
      const updatedFiles = { ...files };
      delete updatedFiles[id];
      setFiles(updatedFiles);
    };
    
    const updateField = (bannerId, fieldName, value) => {
      setBanners(prevArr =>
        prevArr.map(banner =>
          banner.id === bannerId ? { ...banner, [fieldName]: value } : banner
        )
      );
    };
  
    const updateTxtField = (bannerId, txtId, fieldName, value) => {
      setBanners(prevArr =>
        prevArr.map(banner =>
          banner.id === bannerId
            ? {
                ...banner,
                txt: banner.txt.map(txtItem =>
                  txtItem.id === txtId ? { ...txtItem, [fieldName]: value } : txtItem
                )
              }
            : banner
        )
      );
    };
  
    const updateOnField = (bannerId, value) => {
      setBanners(prevArr =>
        prevArr.map(banner =>
          banner.id === bannerId ? { ...banner, on: value ? "on" : null } : banner
        )
      );
    };
  
    const updateTxtOnField = (bannerId, txtId, value) => {
      setBanners(prevArr =>
        prevArr.map(banner =>
          banner.id === bannerId
            ? {
                ...banner,
                txt: banner.txt.map(txtItem =>
                  txtItem.id === txtId ? { ...txtItem, on: value ? "on" : null } : txtItem
                )
              }
            : banner
        )
      );
    };

    const handleFileChange = (bannerId, e) => {
      const file = e.target.files[0];
      setFiles(prevFiles => ({
        ...prevFiles,
        [bannerId]: file,
      }));
    };


    return <div>
      <div className={classes.bannersContainer}>
        {_isArray(banners) ? banners.map((banner) => <div key={name} className={classes.bannerContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(banner.on)}
                    onChange={(e) => updateOnField(banner.id, e.target.checked)}
                    color="secondary"
                  />
                }
                className={classes.checkbox}
                label="Вкл"
              />
              <TextField
                label={`Название`}
                value={banner.name || ""}
                onChange={e => updateField(banner.id, 'name', e.target.value)}
                className={classes.input}
                variant="outlined"
              />
              <TextField
                label={`Описание`}
                value={banner.description || ""}
                onChange={e => updateField(banner.id, 'description', e.target.value)}
                className={classes.input}
                variant="outlined"
              />
              <TextField
                label={`Дистанции`}
                value={banner.distances || ""}
                onChange={e => updateField(banner.id, 'distances', e.target.value)}
                className={classes.input}
                variant="outlined"
              />
              <TextField
                label={`Дата`}
                value={banner.date || ""}
                onChange={e => updateField(banner.id, 'date', e.target.value)}
                className={classes.input}
                variant="outlined"
              />
              <TextField
                label={`Url for image`}
                value={banner.url || ""}
                onChange={e => updateField(banner.id, 'url', e.target.value)}
                className={classes.input}
                variant="outlined"
              />
              <input 
                className={classes.input}
                name={`img${banner.id}`}
                type="file"
                onChange={(e) => handleFileChange(banner.id, e)}
                accept="image/*"
                required={!banner.img || !banner.img_mini}
              />
              {!!banner.img_mini && <a href={banner.url} target="_blank" className={classes.imgContainer}>
                <img src={banner.img_mini}/>
              </a>}
              {banner.txt?.map((t) => <div className={classes.txtContainer} key={t.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(t.on)}
                      onChange={(e) => updateTxtOnField(banner.id, t.id, e.target.checked)}
                      color="secondary"
                    />
                  }
                  className={classes.checkbox}
                  label="Вкл"
                />
                <TextField
                  label={`Url ${t.id}`}
                  value={t.url || ""}
                  onChange={e => updateTxtField(banner.id, t.id, 'url', e.target.value)}
                  className={classes.input}
                  variant="outlined"
                />
                <TextField
                  label={`Name ${t.id}`}
                  value={t.name || ""}
                  onChange={e => updateTxtField(banner.id, t.id, 'name', e.target.value)}
                  className={classes.input}
                  variant="outlined"
                />
              </div>)}
              <IconButton 
                color="secondary"
                variant="contained"
                onClick={() => deleteBanner(banner.id, banner.name)}
              >
                <DeleteForever/>
              </IconButton>
          </div>)
          : null}
      </div>
      {banners?.length < MAX_NUMBER_OF_BANNERS && <Button color="secondary" className={classes.input} variant="outlined" onClick={addNewBanner}>
        {'Добавить баннер'}
      </Button>}
    </div>
};


const useStyles = makeStyles({
  bannersContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: "0.75rem",
  },
  checkbox: {
    width: "400px",
  },
  input: {
    width: "400px",
    marginTop: "1rem",
  },
  imgContainer: {
    marginTop: "1rem",
  },
  bannerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#eee",
    borderRadius: "5px",
    minWidth: "425px",
    marginRight: "1.5rem",
    marginTop: "1rem",
    padding: "1rem 0 1rem",
    '& > *:nth-child(even)': {
      backgroundColor: '#fff ',
    },
  },
  txtContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "5px",
    marginTop: "1rem",
    padding: "0.75rem 0 0.75rem",
    minWidth: "415px",
  }
})
