import React, {useContext} from "react";
import styled from "styled-components";
import {ChallengesContext} from "../../../contexts/Challenges";

import {Card, CardHeader, CardContent, Typography, Chip, Button} from "@material-ui/core";
import moment from "moment"
import {AuthContext} from "../../../contexts/Auth";
import {isCanManageChallenges} from "../../../domain/resolvePermission";
import {useHistory} from "react-router-dom"

export const ChallengesList = () => {
    const challengesContext = useContext(ChallengesContext);
    const authContext = useContext(AuthContext);

    const challenges = challengesContext.challenges || [];
    const canManageChallenge = isCanManageChallenges(authContext.currentUser);

    const handleChangeStage = (registration) => {
        if (canManageChallenge) {
            challengesContext.updateChallengeStage(registration.challenge.id, registration.challenge.stage === 'fix' ? 'beta' : 'fix');
        }
    };

    const handleRating = (challengeKey) => {
        challengesContext.calculateRating(challengeKey);
    };

    return (
        <Container>
            {challenges
                .filter(c => {
                    if (challengesContext.challengeFilter === 'all') return true;

                    if (challengesContext.challengeFilter === 'active' && c.challenge.stage === "beta") return true;

                    if (challengesContext.challengeFilter === 'passed' && c.challenge.stage === "fix") return true;
                })
                .map(c => <Challenge
                    challenge={c}
                    key={c.id}
                    formulas={challengesContext.formulas}
                    onChangeStage={handleChangeStage.bind(this, c)}
                    canManage={canManageChallenge}
                    onCalculateRating={handleRating.bind(undefined, c.challenge.challengeKey, c.challenge.id)}
                />)}
        </Container>
    )
};

const Challenge = ({challenge, formulas, onChangeStage, onCalculateRating, canManage}) => {
    if (!formulas) return null;

    const history = useHistory();
    const formulaSingle = formulas.single.find(f => f.type == challenge.challenge.formulaType)
    const formulaTeam = formulas.team.find(f => f.type == challenge.challenge.formulaType)
    const formula = formulaTeam || formulaSingle || {};

    const isTeamFormula = Boolean(formulaTeam);
    const protocolFile = isTeamFormula ? 'protocol-team' : 'protocol';
    const now = moment();
    const isActive = moment(challenge.regStop).isAfter(now) && moment(challenge.regStart).isBefore(now);

    return (
        <StyledCard>
            <CardHeader
                title={<div><StyledChip
                    onClick={onChangeStage}
                    label={challenge.challenge.stage}
                    color={challenge.challenge.stage == 'beta' ? 'secondary' : 'primary'}/>{challenge.name}</div>}
            >
            </CardHeader>
            <CardContent>
                <Typography variant="body1" component="p">
                    Регистрация: {moment(challenge.regStart).utcOffset(0, false).format('YYYY-MM-DD HH:mm:ss')} - {moment(challenge.regStop).utcOffset(0, false).format('YYYY-MM-DD HH:mm:ss')} - <b>{isActive ? 'Открыта' : 'Закрыта'}</b><br/>
                    Стартовое
                    окно: {moment(challenge.challenge.runStartDate).utcOffset(0, false).format('YYYY-MM-DD HH:mm:ss')} - {moment(challenge.challenge.runStopDate).utcOffset(0, false).format('YYYY-MM-DD HH:mm:ss')}<br/>
                    Формула: {formula.name}<br/>
                    Startreg: {challenge.startreg}<br/>
                    {challenge.challenge.distances.length > 0 && <>
                        Дистанции: {challenge.challenge.distances.map(d => d.distKey).join(', ')}
                        <br/>
                    </>}
                    {!!challenge.challenge.organizer && <>
                        Event: {challenge.challenge.organizer.name}
                        <br/>
                    </>}
                    <br/>
                    <StyledButton variant="outlined" color="primary"
                                  href={`https://challenges.s10.run/startlist?token=${challenge.challenge.challengeKey}`}
                                  target="_blank">
                        Старт лист
                    </StyledButton>
                    <StyledButton variant="outlined" color="primary"
                                  href={`https://challenges.s10.run/${protocolFile}?id=${challenge.challenge.challengeKey}`}
                                  target="_blank">
                        Протокол
                    </StyledButton>
                    {
                        Boolean(canManage) &&
                        <StyledButton variant="outlined" color="secondary"
                                      onClick={() => history.push(`/challenges/${challenge.id}`)}>
                            Редактировать
                        </StyledButton>
                    }
                    {
                        Boolean(canManage && challenge.challenge.stage === 'fix' && !challenge.challenge.isRatingReady) &&
                        <StyledButton variant="outlined" color="secondary"
                                      onClick={onCalculateRating}>
                            Посчитать рейтинг
                        </StyledButton>
                    }
                </Typography>
            </CardContent>
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    margin-bottom: 1rem;
`;

const StyledChip = styled(Chip)`
margin-right:0.5rem!important;
`;

const Container = styled.div`
`;

const StyledButton = styled(Button)`
margin-right:.5rem!important;
`;