import React, { useContext, useEffect } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { OrganizersContext } from "../../../contexts/Organizers";
import { CreateOrganizer } from './CreateOrganizer';
import { useParams } from "react-router-dom"


export const OrganizerContainer = () => {
    const classes = useStyles();
    const organizersContext = useContext(OrganizersContext);
    const { organizerId } = useParams();
    useEffect(() => {
        organizersContext.requestOrganizer(organizerId)
    }, [])
    return (<>
        {
            organizersContext.organizer ? 
            <CreateOrganizer organizer={organizersContext.organizer} />
            : <div className={classes.progressContainer}>
                <CircularProgress/>
            </div>
        }
    </>);
};

const useStyles = makeStyles({
    progressContainer: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "5rem"
    }
})
