import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import {
    makeStyles, Box, TextField, Button, Typography,
    FormControlLabel, Checkbox, IconButton,
} from "@material-ui/core";
import {DeleteForever} from "@material-ui/icons";
import cs from 'classnames';
import { OrganizersContext } from "../../../contexts/Organizers";
import { OrganizersBanners } from "./OrganizersBanners"
import { AuthContext } from "../../../contexts/Auth";
import { isCanManageOrganizers } from "../../../domain/resolvePermission";
import {useHistory} from "react-router-dom"
import { isBoolean as _isBoolean } from "lodash";


export const CreateOrganizer = ({ organizer }) => {
    const classes = useStyles();
    
    const organizersContext = useContext(OrganizersContext);
    const authContext = useContext(AuthContext);
    const history = useHistory();

    const organizerId = organizer?.id;

    const [name, setName] = useState(organizer?.name || "");
    const [withS10Banners, setWithS10Banners] = useState(_isBoolean(organizer?.withS10Banners) ? organizer.withS10Banners  : true);
    const [banners, setBanners] = useState(organizer?.bannersJson ? JSON.parse(organizer.bannersJson) : []);
    const [files, setFiles] = useState({});

    useEffect(() => {
        const canManageOrganizers = isCanManageOrganizers(authContext.currentUser);

        if (!canManageOrganizers)
            history.push("/events")
    }, [])

    const handleCreateOrganizer = async (e) => {
        e.preventDefault();
        const organizerData = {
            name,
            bannersJson: JSON.stringify(banners),
            withS10Banners: Number(withS10Banners),
        };

        if (organizerId) {
            await organizersContext.updateOrganizer(organizerId, organizerData, files)
            window.location.reload();
        } else {
            const newOrganizer = await organizersContext.createOrganizer(organizerData, files);

            window.location = `/events/${newOrganizer.id}`
        }
    }
    return (
        <Container component={"form"} onSubmit={handleCreateOrganizer}>
            {organizerId ?
                <Typography variant="h5">Обновить Event</Typography>
                : <Typography variant="h5">Создать Event</Typography>
            }
                <TextField
                    label={"Название"}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={classes.input}
                    variant="outlined"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={withS10Banners}
                            onChange={(e) => setWithS10Banners(!withS10Banners)}
                            color="secondary"
                        />
                    }
                    className={classes.checkboxContainer}
                    label="Показывать баннеры S10"
                />
                <OrganizersBanners
                    banners={banners}
                    setBanners={setBanners}
                    files={files}
                    setFiles={setFiles}
                />
                <Button 
                    type="submit" 
                    color="secondary" 
                    className={classes.input} 
                    variant="outlined"
                >
                    {organizerId ? 'Обновить' : 'Создать'}
                </Button>
        </Container>
    )
};

const Container = styled(Box)`
    max-width: 1024px;
    margin: 1rem auto;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
`;

const useStyles = makeStyles({
    input: {
        width: "400px",
        marginTop: "1rem",
    },
    linkInput: {
        marginRight: '1rem',
    },
    numberInput: {
        /* Chrome, Safari, Edge, Opera */
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0
        },
        /* Firefox */
        "& input[type=number]": {
            "-moz-appearance": "textfield"
        }
    },
    checkboxContainer: {
        marginTop: "1rem",
    }
});