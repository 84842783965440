export const isCanViewShop = (currentUser) => {
    return resolvePermission(currentUser, "shop_all");
};

export const isCanManageChallenges = (currentUser) => {
    return resolvePermission(currentUser, "challenges_all");
};

export const isCanManageClubs = (currentUser) => {
    return resolvePermission(currentUser, "clubs_all");
};

export const isCanManageOrganizers = (currentUser) => {
    return resolvePermission(currentUser, "organizers_all");
};

export const isCanViewTracks = (currentUser) => {
    return resolvePermission(currentUser, "tracks_all");
};

const resolvePermission = (currentUser, permission) => {
    return currentUser && currentUser.permissions.indexOf(permission) >= 0;
};