import React from "react";
import { OrganizersProvider } from "../../../contexts/Organizers";
import { OrganizerContainer } from "../../components/organizers/OrganizerContainer";

import Header, { pages } from "../Header";

export const OrganizerPage = () => {
    return (
        <OrganizersProvider>
            <Header activePage={pages.ORGANIZERS} />
            <OrganizerContainer/>
        </OrganizersProvider>
    );
};