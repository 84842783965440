import React, { useContext } from 'react';
import styled from 'styled-components';
import { Button } from "@material-ui/core";
import { Link } from 'react-router-dom';

import { OrganizersList } from './OrganizersList';
import { AuthContext } from '../../../contexts/Auth';
import { isCanManageOrganizers } from '../../../domain/resolvePermission';


export const OrganizersContainer = () => {
    const authContext = useContext(AuthContext);

    return <Container>
        <ControlContainer>
            {isCanManageOrganizers(authContext.currentUser) && 
                <Button component={Link} to="/events/new" variant="contained" color="primary" >Создать Event</Button>}
        </ControlContainer>
        <OrganizersList/>
    </Container>
};

const Container = styled.div`
max-width: 1024px;
margin: 1rem auto;
`;

const ControlContainer = styled.div`
display: flex;
justify-content: space-between;
margin: 1rem 0;
`;